import type { ImpressionSequence, OfferSlot } from '@thanks/impression-type';
import { useMemo } from 'react';

const adoptWelcome = (brand: ImpressionSequence['brand']): OfferSlot => {
	// TODO: FIX THIS VERY STRANGE FUNCTION
	if (brand.type === 'splash') {
		return {
			type: 'splash',
			data: brand,
		} as const;
	}

	return {
		type: 'splash',
		data: brand,
	};
};

export const useSlots = (impression: ImpressionSequence): OfferSlot[] => {
	return useMemo(
		() => [adoptWelcome(impression.brand), ...impression.slots],
		[impression.slots],
	);
};
