import { InlineButton, InlineLink } from '@smile/ds/actions';
import { useControlModal } from '@smile/ds/modal';

import { font } from '@smile/tokens/font';

import type { FC } from 'react';
import { lazy, Suspense } from 'react';

import { Asterisk } from '../control/state-controller';

import * as styles from './styles';
import { TextXs } from '@smile/ds/typography';

const Terms = lazy(() => import('../terms'));

type TermsType = {
	text: string;
	logo: string;
	logoBg: string;
	logoStroke?: string | undefined;
};

export type FooterProps = {
	terms: TermsType | undefined;
	emailNotification: boolean;
	experienceId: string | undefined;
};

// FIXME: extract into shared constants
const THANKS_PRIVACY_POLICY_URL = 'https://www.thanks.co/terms-and-privacy';
const THANKS_URL = 'https://www.thanks.co';

const insuranceDotComAdId = 'a9e14ecb-f82d-4472-819f-d5e8f948b0ec';

export const Footer: FC<FooterProps> = ({
	terms,
	emailNotification,
	experienceId,
}) => {
	const hasTerms = Boolean(terms);

	const termsControl = useControlModal();

	return (
		<>
			<TextXs as="footer" className={styles.footer} color="muted">
				<p>
					<Asterisk />
					{hasTerms ? (
						<InlineButton
							onClick={termsControl.toggle}
							actionName="terms-and-conditiond"
							underlined
						>
							{experienceId === insuranceDotComAdId
								? "T&C's and Disclaimer"
								: "T&C's Apply"}
						</InlineButton>
					) : null}
					{emailNotification &&
					experienceId !== insuranceDotComAdId ? (
						<>
							{hasTerms ? '. ' : null}
							Details will be emailed
						</>
					) : null}
				</p>
				<p>
					<span style={{ fontWeight: font.weight.bold }}>
						<InlineLink
							href={THANKS_URL}
							newTab
							actionName="thanksad"
							underlined
						>
							Thanks
						</InlineLink>
					</span>{' '}
					|{' '}
					<InlineLink
						href={THANKS_PRIVACY_POLICY_URL}
						newTab
						actionName="thanksad privacy-policy"
						underlined
					>
						Terms & Privacy
					</InlineLink>
				</p>
			</TextXs>
			{terms && termsControl.modal.defined ? (
				<Suspense fallback="">
					<Terms
						logo={terms.logo}
						logoBg={terms.logoBg}
						control={termsControl.modal}
						onClose={termsControl.close}
					>
						{terms.text}
					</Terms>
				</Suspense>
			) : null}
		</>
	);
};
