import { BREAKPOINTS } from '@theway/helpers-css/breakpoints';

export type DeviceMode = 'desktop' | 'mobile';

export const getDeviceMode = (): DeviceMode => {
	if (typeof window === 'undefined') {
		return 'desktop';
	}

	if (window.innerWidth >= BREAKPOINTS.sm) {
		return 'desktop';
	}

	return 'mobile';
};
