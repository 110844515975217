import type { ImpressionSequence } from '@thanks/impression-type';
import { useEffect } from 'react';

export type StepPreloader = (
	step: number,
	impression: ImpressionSequence,
) => Promise<void>;

const PRELOAD_TIMEOUT = 5000;

export const useStepPreload = (
	impression: ImpressionSequence,
	preloadStep: StepPreloader,
	currentStep: number,
	totalSteps: number,
) => {
	// preload
	useEffect(() => {
		const nextStep = Math.min(currentStep + 1, totalSteps - 1);
		preloadStep(nextStep, impression);
	}, [currentStep]);

	// preload all steps
	useEffect(() => {
		setTimeout(() => {
			Array.from({ length: totalSteps }).reduce<Promise<void>>(
				(acc, _, id) => {
					return acc.then(() => preloadStep(id, impression));
				},
				Promise.resolve(),
			);
		}, PRELOAD_TIMEOUT);
	}, []);
};
