import { useAutofocusWithin } from '@smile/ds/a11y';
import { PrimaryButton, SecondaryButton } from '@smile/ds/actions';
import { ArrowRight } from '@smile/ds/icons/ArrowRight';
import type { FC, ReactNode } from 'react';

import * as styles from './styles';
import { H1, TextMd } from '@smile/ds/typography';

type ContentProps = {
	text: ReactNode;
	subtext: ReactNode;
	actionText: string;
	positionOfSubtext: 'above' | 'below';
	onGetStarted(): void;
	onClose(): void;
};

declare module '@smile/experimentation' {
	export interface FeatureFlags {}
	export interface RuntimeConfiguration {
		/**
		 * controls "click outside" and "esc" key behavior
		 */
		disableClickOutside: boolean;
		/**
		 * controls "esc" key behavior
		 */
		disableEscClose: boolean;
	}
}

export const SplashContent: FC<ContentProps> = ({
	text,
	subtext,
	onGetStarted,
	onClose,
	actionText: defaultActionText = 'Show me my offers',
	positionOfSubtext = 'below',
}) => {
	const buttonsRef = useAutofocusWithin<HTMLDivElement>(true);

	const sub = (
		<TextMd as="p" className={styles.subText}>
			{subtext}
		</TextMd>
	);

	return (
		<div className={styles.component}>
			<div className={styles.textWrapper}>
				{positionOfSubtext === 'above' && sub}
				<H1 as="h2" className={styles.text}>
					{text}
				</H1>
				{positionOfSubtext === 'below' && sub}
			</div>
			<div ref={buttonsRef} className={styles.buttonsGroup}>
				<PrimaryButton
					onClick={onGetStarted}
					iconAfter={<ArrowRight />}
					actionName="get-started"
					autoFocus
				>
					{defaultActionText}
				</PrimaryButton>
				<div style={{ visibility: 'hidden' }}>
					<SecondaryButton
						className={styles.hidden}
						onClick={onClose}
						actionName="close-no-thanks"
						disabled={true}
					>
						Next offer
					</SecondaryButton>
				</div>
			</div>
		</div>
	);
};

export const Thanks: FC<ContentProps> = (props) => {
	return <SplashContent {...props} />;
};
