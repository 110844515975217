import { useCustomerCommunication } from '@smile/context';
import { concatSearchPart } from '@smile/url-utils';
import type { CodeItem } from '@thanks/impression-type';
import { DIRECT_CODE } from '@thanks/impression-type';
import type { FC, ReactElement } from 'react';
import { useCallback, useEffect, useState } from 'react';

import { useLinkTrackers } from '../analytics/tracker-link';
import { getPiiToken } from '../services/pii/pii-bridge';

import { environmentDi } from './environmentDi';

const refPrefix = 'ref:';

type CodeGetter = () => void;
type CodeStatus = 'ready' | 'loading' | 'error';

const DynamicCouponFetcher: FC<{
	offer: CodeItem;
	href: string;
	impression: string;
	tokenExtra: string;
	render(
		code: string,
		getCode: CodeGetter,
		status: CodeStatus,
		href: string,
	): ReactElement;
}> = ({ render, offer, href, impression, tokenExtra }) => {
	const [status, setStatus] = useState<CodeStatus>('loading');
	const [code, setCode] = useState('');

	const getCode = useCallback(() => {
		(async () => {
			const url = new URLSearchParams({
				impression,
				offer: offer.code.substr(refPrefix.length),
			});

			try {
				const code = await fetch(
					concatSearchPart(
						'/api/claim-coupon?' + url.toString(),
						tokenExtra,
					),
				);

				if (!code.ok || code.status !== 200) {
					setStatus('error');
				}

				setCode(((await code.json()) as any).code);
				setStatus('ready');
			} catch (e) {
				console.error(e);
				setStatus('error');
			}
		})();
	}, [offer, tokenExtra]);

	return render(
		code,
		getCode,
		code ? 'ready' : status,
		concatSearchPart(href, tokenExtra),
	);
};

const RealCouponFetcher: FC<{
	offer: CodeItem;
	impression: string;
	piiRegion: string;
	children(
		code: string,
		getCode: CodeGetter,
		status: CodeStatus,
		href: string,
	): ReactElement;
}> = ({ children, offer, impression, piiRegion }) => {
	const [token, setToken] = useState<string | undefined>(undefined);

	const {
		setCommunicationsAvailable: reportCommunicationAvailability,
		customerSharedEmail,
	} = useCustomerCommunication();

	const href = useLinkTrackers(offer.href);

	useEffect(() => {
		if (!offer.communicationAvailable) {
			return;
		}

		const getToken = getPiiToken({
			impression,
			piiRegion,
			subject: 'notification',
			offerType: 'coupon',
			offer,
			customerSharedEmail,
		});

		getToken.then(setToken);

		getToken.then((token) => {
			reportCommunicationAvailability(Boolean(token));
		});

		return () => {
			reportCommunicationAvailability(false);
		};
	}, []);

	const tokenExtra = token ? `piitoken=${encodeURIComponent(token)}` : '';

	if (offer.code === DIRECT_CODE) {
		return children(
			DIRECT_CODE,
			() => null,
			'ready',
			concatSearchPart(href, tokenExtra),
		);
	}

	if (!offer.code || !offer.code.startsWith(refPrefix)) {
		return children(
			offer.code,
			() => null,
			'ready',
			concatSearchPart(href, tokenExtra),
		);
	}

	return (
		<DynamicCouponFetcher
			offer={offer}
			href={href}
			render={children}
			impression={impression}
			tokenExtra={tokenExtra}
		/>
	);
};

const FakeCouponFetcher: typeof RealCouponFetcher = ({ children, offer }) => {
	if (offer.code === DIRECT_CODE) {
		return children(DIRECT_CODE, () => null, 'ready', offer.href);
	}

	if (!offer.code || !offer.code.startsWith(refPrefix)) {
		return children(offer.code, () => null, 'ready', offer.href);
	}

	return children('XXX-YYY', () => null, 'ready', offer.href);
};

export const CouponFetcher = environmentDi(RealCouponFetcher, {
	test: FakeCouponFetcher,
	preview: FakeCouponFetcher,
});
