import { atom, useAtom } from 'jotai';
import { useDebugValue, useLayoutEffect } from 'react';
import type { FC, ReactNode, PropsWithChildren } from 'react';

export interface Teleporter {
	Source: FC<PropsWithChildren>;
	Target: FC<PropsWithChildren>;
	HiddenFallback: FC<PropsWithChildren>;
}

export const createTeleporter = (): Teleporter => {
	const portalSignal = atom<ReactNode | null>(null);

	const Target: FC<PropsWithChildren> = ({ children }) => {
		const [value] = useAtom(portalSignal);
		useDebugValue(value);

		return <>{value ?? children}</>;
	};

	const Source: FC<PropsWithChildren> = ({ children }) => {
		const [, setValue] = useAtom(portalSignal);
		useDebugValue(children);

		useLayoutEffect(() => {
			setValue(children);

			return () => {
				setValue(null);
			};
		}, [children]);

		return null;
	};

	const HiddenFallback: FC<PropsWithChildren> = ({ children }) => {
		const [value] = useAtom(portalSignal);

		return (
			<div style={{ display: value ? 'none' : 'contents' }}>
				{children}
			</div>
		);
	};

	return { Source, Target, HiddenFallback };
};
