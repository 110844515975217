import { INIT_CMD, READY_EVENT } from './consts';
import type { Callback } from './types';
import { decodeMessage, encodeMessage, executeEvent } from './utils';

export const createRNTunnelPrimary = <
	InCommands extends string = '',
	OutCommands extends string = '',
>(
	name: string,
) => {
	const events: Record<string, Callback[]> = {};

	const pushMessage = (data: string) => {
		const { cmd, payload, error } = decodeMessage(data);

		if (!error) {
			executeEvent(events, cmd, payload);
		}
	};

	function send(cmd: string, payload: any) {
		// @ts-expect-error - let's handle RN headers later
		window.ReactNativeWebView.postMessage(
			JSON.stringify(encodeMessage(cmd, payload)),
		);
	}

	return {
		/**
		 *
		 * @deprecated - no effect
		 * @param _empty placeholder
		 */
		connect(_empty: any) {
			send(INIT_CMD, name);
		},
		pushMessage,
		on(cmd: InCommands, handle: Callback) {
			(events[cmd] = events[cmd] || []).push(handle);

			return () => {
				events[cmd] = events[cmd].filter((cb) => cb !== handle);
			};
		},
		send(cmd: OutCommands, payload?: any) {
			send(cmd, payload);
		},
	};
};

export const createRNTunnelSecondary = <
	InCommands extends string,
	OutCommands extends string,
>(
	name: string,
	postMessage: (message: any) => void,
) => {
	const events: Record<string, Callback[]> = {};
	let ready = false;

	const onMessage = (data: unknown) => {
		const { cmd, payload, error } = decodeMessage(data);

		if (!error) {
			executeEvent(events, cmd, payload);
		} else {
			console.log('error decoding event', data, error);
		}
	};

	const pushMessage = (data: unknown) => {
		const { cmd, payload, error } = decodeMessage(data);

		if (ready) {
			onMessage(data);

			return;
		}

		if (!error && cmd === INIT_CMD && payload === name) {
			ready = true;
			executeEvent(events, READY_EVENT, undefined);
		}
	};

	return {
		on(cmd: typeof READY_EVENT | InCommands, handle: Callback) {
			(events[cmd] = events[cmd] || []).push(handle);
		},
		isReady() {
			return ready;
		},
		pushMessage,
		send(cmd: OutCommands, payload?: any) {
			if (!ready) {
				throw new Error('trying to `send` into non initialized tunnel');
			}

			postMessage(encodeMessage(cmd, payload));
		},
	};
};
