import type { ImpressionSequence } from '@thanks/impression-type';

import { createContext } from 'react';

import type { DeviceMode } from './get-device-mode';

export type Context = {
	impression: ImpressionSequence;
	mode: DeviceMode;
};

export const context = createContext<Context>({
	impression: undefined as any,
	mode: undefined as any,
});
