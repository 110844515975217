import { useAutofocusWithin } from '@smile/ds/a11y';
import type { FC, PropsWithChildren, ReactNode } from 'react';

import { Asterisk } from '../control/state-controller';

import { OFFER_DESCRIPTION } from './const';
import * as styles from './styles';
import { H2, TextMd, TextSm, TextXs } from '@smile/ds/typography';

export type OfferLayoutProps = {
	text: ReactNode;
	subtext: ReactNode;
	disclaimer: ReactNode | undefined;
};

const DefaultOfferLayout: FC<PropsWithChildren<OfferLayoutProps>> = ({
	text,
	subtext,
	disclaimer,
	children,
}) => {
	const activeSectionRef = useAutofocusWithin<HTMLDivElement>(true);

	return (
		<>
			<div className={styles.component}>
				<H2 as="h2" id={OFFER_DESCRIPTION}>
					{text}
				</H2>
				<TextMd as="p" className={styles.subtext}>
					{subtext}
					<Asterisk />
				</TextMd>
				{disclaimer && (
					<TextSm as="p" color="muted" className={styles.disclaimer}>
						<sup>*</sup>
						{disclaimer}
					</TextSm>
				)}
				<div className={styles.actions} ref={activeSectionRef}>
					{children}
				</div>
			</div>
		</>
	);
};

const GamblingOfferLayout: FC<PropsWithChildren<OfferLayoutProps>> = ({
	text,
	subtext,
	children,
}) => {
	const activeSectionRef = useAutofocusWithin<HTMLDivElement>(true);

	return (
		<>
			<div className={styles.component}>
				<H2 as="h2" id={OFFER_DESCRIPTION}>
					{text}
				</H2>
				<TextXs as="p" className={styles.gambling}>
					{subtext}
				</TextXs>
				<div className={styles.actions} ref={activeSectionRef}>
					{children}
				</div>
			</div>
		</>
	);
};

const DualOfferLayout: FC<PropsWithChildren<OfferLayoutProps>> = ({
	text,
	subtext,
	children,
}) => {
	const activeSectionRef = useAutofocusWithin<HTMLDivElement>(true);

	return (
		<div className={styles.dualComponent}>
			<H2 as="h2" id={OFFER_DESCRIPTION}>
				{text}
			</H2>
			<TextMd as="p" className={styles.subtext}>
				{subtext}
				<Asterisk />
			</TextMd>
			<div className={styles.actions} ref={activeSectionRef}>
				{children}
			</div>
		</div>
	);
};

export const OfferLayout: FC<
	PropsWithChildren<
		OfferLayoutProps & { mode?: 'default' | 'gambling' | 'dual' }
	>
> = ({ mode = 'default', ...props }) => {
	switch (mode) {
		case 'gambling':
			return <GamblingOfferLayout {...props} />;
		case 'dual':
			return <DualOfferLayout {...props} />;
		default:
			return <DefaultOfferLayout {...props} />;
	}
};
