import { xorTransform } from '@theway/helpers-fake-crypto';

export type PersonalToken = {
	piId: string;
	email?: string | undefined;
	firstName?: string | undefined;
};

export const encodePersonalToken = (hash: string, token: PersonalToken) => {
	const base64 = btoa(JSON.stringify(token));

	return xorTransform(hash, base64, 64);
};

export const decodePersonalToken = (
	hash: string,
	token: string,
): PersonalToken => {
	const data = xorTransform(hash, token, 64);

	return JSON.parse(atob(data));
};
