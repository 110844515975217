import { useCallback, useState } from 'react';

export const useQueue = <T>(limit: number, initial: T[] = []) => {
	const [steps, setSteps] = useState<T[]>(initial);
	const pushStep = useCallback((step: T) => {
		setSteps((oldSteps) => {
			const newSteps = [...oldSteps, step];

			return newSteps.slice(Math.max(newSteps.length - limit, 0));
		});
	}, []);

	return [steps, pushStep] as const;
};
