import type { FC, PropsWithChildren } from 'react';

import { Header } from '../header';
import { Partnership } from '../partnership';

import { Badge } from './Badge';
import { Content } from './Content';
import { Decoration } from './Decoration';
import { DualPartners } from './DualPartners';
import { Presentation } from './Presentation';
import { ShareButton } from './Share';
import * as styles from './styles';
import type { CommonProps } from './types';

export const Mobile: FC<PropsWithChildren<CommonProps>> = ({
	children,
	...props
}) => {
	return (
		<div className={styles.mobile}>
			<div className={styles.wrapper}>
				<Header {...props}>{props.statusText}</Header>
				<Decoration
					backgroundColorOverride={props.backgroundColorOverride}
					theme={props.theme}
				>
					<div className={styles.decorationWrapper}>
						<Presentation
							hidden={
								props.view ? props.view[0] === 'plain' : false
							}
							currentImage={props.currentImage}
							previousImage={props.previousImage}
						/>
						<ShareButton onShare={props.onShare} />
						{props.offerType === 'dual' && (
							<DualPartners
								height={44}
								firstBrand={props.firstBrand}
								secondBrand={props.secondBrand}
							/>
						)}
					</div>
					{props.badge && <Badge>{props.badge}</Badge>}
				</Decoration>
				<Content
					theme={props.theme}
					terms={props.terms}
					emailNotification={props.emailNotification}
					experienceId={props.experienceId}
				>
					{props.brand && (
						<div className={styles.partnership}>
							<Partnership brand={props.brand} logoWidth={68} />
						</div>
					)}
					{children}
				</Content>
			</div>
		</div>
	);
};
