import { InlineLink } from '@smile/ds/actions';
import { fromMarkAst } from '@theway/markdown-ui';
import {
	composeFactory,
	createSlotRender,
	renderer,
} from '@theway/type-broker';

import { useLinkTrackers, WithLinkTracker } from '../../analytics/tracker-link';
import { CouponFetcher } from '../../components/coupon-fetcher';
import { GiftcardFetcher } from '../../components/giftcard-fetcher';
import { SubscriptionController } from '../../components/subscription-controller';
import { ThanksGiveawayController } from '../../components/thanks-giveaway-controller';

const offerRenderer = renderer(
	'offer',
	() => import('@meleagris-gallopavo/widget/offer'),
	(_type, { Offer }, data, props) => (
		<WithLinkTracker url={data.href}>
			{(href) => (
				<Offer
					{...props}
					{...data}
					href={href}
					text={fromMarkAst(data.text)}
					subtext={fromMarkAst(data.subtext)}
					disclaimer={fromMarkAst(data.disclaimer)}
				/>
			)}
		</WithLinkTracker>
	),
);

const doubleOfferRenderer = renderer(
	'dual-offer',
	() => import('@meleagris-gallopavo/widget/dual-offer'),
	(_type, { DualOffer }, data, props) => (
		<DualOffer
			{...props}
			{...data}
			text={fromMarkAst(data.text)}
			subtext={fromMarkAst(data.subtext)}
			disclaimer={fromMarkAst(data.disclaimer)}
			firstOffer={{
				caption: data.linkCaption,
				href: useLinkTrackers(data.href, { order: 1 }),
				advertiserId: data.dualSettings.firstOffer.advertiserId,
				bgColor: data.dualSettings.firstOffer.actionBackgroundColor,
				textColor: data.dualSettings.firstOffer.actionTextColor,
			}}
			secondOffer={{
				caption: data.secondaryLinkCaption,
				href: useLinkTrackers(data.href, { order: 2 }),
				advertiserId: data.dualSettings.secondOffer.advertiserId,
				bgColor: data.dualSettings.secondOffer.actionBackgroundColor,
				textColor: data.dualSettings.secondOffer.actionTextColor,
			}}
		/>
	),
);

const codeRenderer = renderer(
	'code',
	() => import('@meleagris-gallopavo/widget/code-offer'),
	(_type, { CodeOffer }, data, props, impression) => (
		<CouponFetcher
			offer={data}
			impression={impression.impressionId}
			piiRegion={impression.piiRegion}
		>
			{(code, getCode, status, href) => (
				<CodeOffer
					{...props}
					{...data}
					href={href}
					text={fromMarkAst(data.text)}
					subtext={fromMarkAst(data.subtext)}
					disclaimer={fromMarkAst(data.disclaimer)}
					code={code}
					getCode={getCode}
					status={status}
					linkCaption={data.linkCaption}
				/>
			)}
		</CouponFetcher>
	),
);

const giftcardRenderer = renderer(
	'transaction',
	() => import('@meleagris-gallopavo/widget/giftcard-offer'),
	(_type, { GiftCardOffer }, data, props, impression) => (
		<GiftcardFetcher
			offer={data}
			impression={impression.impressionId}
			piiRegion={impression.piiRegion}
			onNext={props.onNext}
		>
			{(
				{ getCode, usesExternalWindow, onBeforeSettle, onAfterSettle },
				{ email },
			) => (
				<GiftCardOffer
					{...props}
					{...data}
					email={email}
					product={{
						country: data.giftcard.country,
						currency: data.giftcard.currency,
						price: data.giftcard.cost,
						description: data.giftcard.name,
						// activeSlide starts from 1, not 0
						brand: props.slides[props.activeSlide - 1],
					}}
					promo={data.promotion?.name}
					text={fromMarkAst(data.text)}
					subtext={fromMarkAst(data.subtext)}
					disclaimer={fromMarkAst(data.disclaimer)}
					usesExternalWindow={usesExternalWindow || false}
					getPaymentInformation={getCode}
					onBeforeSettle={onBeforeSettle}
					onAfterSettle={onAfterSettle}
					// status={status}
					linkCaption={data.linkCaption}
				/>
			)}
		</GiftcardFetcher>
	),
);

const subscriptionRenderer = renderer(
	'subscription',
	() => import('@meleagris-gallopavo/widget/subscription-offer'),
	(_type, { SubscriptionOffer }, data, props, impression) => {
		const slotImage = {
			url: (data.mg || impression.brand.mg)?.image_desktop || '',
			xsUrl: (data.mg || impression.brand.mg)?.image_mobile || '',
		};

		return (
			<SubscriptionController
				offer={data}
				impression={impression.impressionId}
				piiRegion={impression.piiRegion}
				onNext={props.onNext}
			>
				{({ prefilled, processData }) => (
					<SubscriptionOffer
						{...props}
						{...data}
						image={slotImage}
						text={fromMarkAst(data.text)}
						subtext={fromMarkAst(data.subtext)}
						disclaimer={fromMarkAst(data.disclaimer)}
						screen2Text={fromMarkAst(data.screen2Text)}
						screen2Subtext={fromMarkAst(data.screen2Subtext)}
						fieldset={data.subscription.collect}
						subscriptionName={data.subscription.name}
						TNC={data.subscription.link}
						thanksTNC={data.subscription.thanksLink}
						actionName={data.subscription.actionText}
						{...prefilled}
						onInformationEnter={processData}
					/>
				)}
			</SubscriptionController>
		);
	},
);

const thanksGiveawayRenderer = renderer(
	'thanks-giveaway',
	() => import('@meleagris-gallopavo/widget/subscription-offer'),
	(_type, { SubscriptionOffer }, data, props, impression) => {
		const slotImage = {
			url: (data.mg || impression.brand.mg)?.image_desktop || '',
			xsUrl: (data.mg || impression.brand.mg)?.image_mobile || '',
		};

		const actionName = data.linkCaption;

		return (
			<WithLinkTracker url={data.href}>
				{(href) => (
					<ThanksGiveawayController
						impression={impression}
						offer={data}
						onNext={props.onNext}
					>
						{({ processData, prefilled }) => (
							<SubscriptionOffer
								{...props}
								{...data}
								image={slotImage}
								text={fromMarkAst(data.text)}
								subtext={fromMarkAst(data.subtext)}
								disclaimer={fromMarkAst(data.disclaimer)}
								screen2Text={fromMarkAst(data.screen2Text)}
								screen2Subtext={fromMarkAst(
									data.screen2Subtext,
								)}
								fieldset={{ email: true }}
								subscriptionName={'Thanks'}
								eulaOverride={
									<>
										By clicking {actionName} you agree to
										share your info with{' '}
										<InlineLink href="https://thanks.co">
											Thanks
										</InlineLink>{' '}
										for use in accordance with their Privacy
										Policy
									</>
								}
								TNC={''}
								thanksTNC="https://www.thanks.co/terms-and-privacy"
								actionName={actionName}
								{...prefilled}
								onInformationEnter={(data, options) => {
									window.open(href);

									return processData(data, options);
								}}
							/>
						)}
					</ThanksGiveawayController>
				)}
			</WithLinkTracker>
		);
	},
);

const factRenderer = renderer(
	'fact',
	() => import('@meleagris-gallopavo/widget/exit'),
	(
		_type,
		{ Bye },
		data,
		props,
		_impression,
		ownProps: { onClose(): void },
	) => (
		<Bye
			onClose={ownProps.onClose}
			{...props}
			actionLabel={data.actionText}
			text={fromMarkAst(data.text)}
			subtext={fromMarkAst(data.subText)}
		/>
	),
);

const TYPES = composeFactory([
	offerRenderer,
	doubleOfferRenderer,
	codeRenderer,
	giftcardRenderer,
	subscriptionRenderer,
	factRenderer,
	thanksGiveawayRenderer,
] as const);

export const slotRender = createSlotRender(TYPES);
