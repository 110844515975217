export const pickRandom = <T>(data: readonly T[]): T =>
	data[Math.floor(Math.random() * data.length)];

export type EXPERIMENT = 'experiment' | 'control' | 'not-enrolled';

/**
 * Runs controlled experiment
 * @param percentage 0-100
 * @returns
 *  - experiment: experiment cohort
 *  - control: control comparison cohort
 *  - not-enrolled: rest of population
 */
export const experimentRollout = (percentage: number): EXPERIMENT => {
	const randomValue = Math.random() * 100;

	if (randomValue < percentage) {
		return 'experiment';
	} else if (randomValue < percentage * 2) {
		return 'control';
	} else {
		return 'not-enrolled';
	}
};

export const isExperimentalCohort = (cohort: EXPERIMENT): boolean =>
	['experiment', 'control'].includes(cohort);
