import { clsx } from 'clsx';
import { AutoFocusControl, VisuallyHidden } from '@smile/ds/a11y';
import { CloseIcon } from '@smile/ds/icons/Close';
import { Logo } from '@smile/ds/logo';
import { analyticsAttribution } from '@theway/analytics';
import {
	useRef,
	useState,
	type FC,
	type ReactNode,
	useCallback,
	useEffect,
	createRef,
} from 'react';

import * as styles from './styles';
import { PopupTeleporterTarget } from '@theway/shards';

const arrow = `<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M10.0002 4.16669L10.0002 15.8334M10.0002 15.8334L15.8335 10M10.0002 15.8334L4.16683 10" stroke="currentColor" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
</svg>`;

export const Layout: FC<{
	statusText: string;
	onClose(): void;
	steps: Array<ReactNode>;
}> = ({ steps, statusText, onClose }) => {
	const [currentStep, setCurrentStep] = useState(0);
	const contentRef = useRef<HTMLDivElement>(null);
	const [stepsRefs] = useState(() =>
		new Array(steps.length).fill(1).map(() => createRef<HTMLDivElement>()),
	);

	const handleIntersection = useCallback(
		(entries: IntersectionObserverEntry[]) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					const stepIndex = stepsRefs.findIndex(
						(ref) => ref.current === entry.target,
					);
					if (stepIndex !== -1) {
						setCurrentStep(stepIndex);
					}
				}
			});
		},
		[],
	);

	useEffect(() => {
		const observer = new IntersectionObserver(handleIntersection, {
			root: contentRef.current,
			threshold: 0.5,
		});

		stepsRefs.forEach(({ current: step }) => {
			if (step) {
				observer.observe(step);
			}
		});

		return () => {
			observer.disconnect();
		};
	}, [handleIntersection]);

	const handleDownClick = () => {
		if (contentRef.current && currentStep < steps.length - 1) {
			const nextStep = currentStep + 1;
			const nextStepElement = stepsRefs[nextStep].current;
			nextStepElement?.scrollIntoView({ behavior: 'smooth' });
		}
	};

	//header behaviour while scrolling
	const [isScrolled, setIsScrolled] = useState(false);

	const handleScroll = useCallback(() => {
		if (contentRef.current) {
			const scrollTop = contentRef.current.scrollTop;
			setIsScrolled(scrollTop > 10);
		}
	}, []);

	useEffect(() => {
		const contentElement = contentRef.current;
		if (contentElement) {
			contentElement.addEventListener('scroll', handleScroll);
			return () =>
				contentElement?.removeEventListener('scroll', handleScroll);
		}
		return undefined;
	}, [handleScroll]);

	//swipe down
	const swapRef = useRef<HTMLDivElement>(null);
	const [isSwipedDown, setIsSwipedDown] = useState(false);
	const [startY, setStartY] = useState<number | null>(null);

	const handleTouchStart = (event: TouchEvent) => {
		event.stopPropagation();
		setStartY(event.touches[0].clientY);
	};

	const handleTouchMove = (event: TouchEvent) => {
		event.stopPropagation();
		if (startY !== null) {
			const currentY = event.touches[0].clientY;
			const deltaY = currentY - startY;

			if (deltaY > 50) {
				setIsSwipedDown(true);
				setTimeout(() => onClose(), 300);
			}
		}
	};

	useEffect(() => {
		const containerElement = swapRef.current;
		if (containerElement) {
			containerElement.addEventListener('touchstart', handleTouchStart);
			containerElement.addEventListener('touchmove', handleTouchMove);

			return () => {
				containerElement.removeEventListener(
					'touchstart',
					handleTouchStart,
				);
				containerElement.removeEventListener(
					'touchmove',
					handleTouchMove,
				);
			};
		}
		return undefined;
	}, [startY]);

	return (
		<div
			className={clsx(
				styles.component,
				isSwipedDown && styles.swapped,
				'widget-top-wrapper',
			)}
		>
			<div className={clsx(styles.header, isScrolled && styles.hidden)}>
				<Logo theme={'light'} width={30} />
				{!isScrolled && statusText}
				<button
					className={styles.closeButton}
					type="button"
					onClick={onClose}
					aria-label="Close thanks widget"
					{...analyticsAttribution('close')}
				>
					<CloseIcon />
					<VisuallyHidden>Close thanks ads</VisuallyHidden>
				</button>
			</div>
			<div className={styles.wrapper}>
				<div className={styles.content} ref={contentRef}>
					{steps.map((step, index) => (
						<div
							ref={stepsRefs[index]}
							style={{ scrollSnapAlign: 'start' }}
							key={index}
						>
							<AutoFocusControl
								allowAutoFocus={
									false /*index == currentStep - 1*/
								}
							>
								{step}
							</AutoFocusControl>
						</div>
					))}
				</div>
			</div>
			<div className={styles.swap} ref={swapRef} />
			{currentStep === 0 && (
				<button className={styles.down} onClick={handleDownClick}>
					<div dangerouslySetInnerHTML={{ __html: arrow }} />
					<VisuallyHidden>Scroll down</VisuallyHidden>
				</button>
			)}
			<PopupTeleporterTarget />
		</div>
	);
};
