import { useAnalyticsEvent } from '@theway/analytics';
import { useEffect, useRef } from 'react';

import { createStackedController } from '../../stacked';

const isEscKey = (event: KeyboardEvent) =>
	event.code === 'Escape' || event.key === 'Escape' || event.keyCode === 27;

export const useConnectEscHandler = <T extends HTMLDivElement>(
	callback: () => void,
	eventName: string,
	isEnabled = true,
) => {
	const ref = useRef<T>(null);
	const callbackRef = useRef(callback);
	const uiEvent = useAnalyticsEvent();

	callbackRef.current = callback;

	useEffect(() => {
		const node = ref.current;

		if (node && isEnabled) {
			const cb = (event: KeyboardEvent) => {
				if (isEscKey(event) && !event.defaultPrevented) {
					callbackRef.current();
					event.preventDefault();
					uiEvent(`esc-${eventName}`, event.currentTarget);
				}
			};

			node.addEventListener('keydown', cb);

			return () => node.removeEventListener('keydown', cb);
		}

		return () => {
			//
		};
	}, [callback, isEnabled]);

	return ref;
};

const useEscStack = createStackedController();

export const useEscHandler = (
	callback: () => void,
	eventName: string,
	isEnabled = true,
) => {
	const callbackRef = useRef(callback);
	const uiEvent = useAnalyticsEvent();

	callbackRef.current = callback;

	const isTopMost = useEscStack(isEnabled, eventName);

	useEffect(() => {
		if (isTopMost) {
			const cb = (event: KeyboardEvent) => {
				// Check if the event target is an input element
				if (event.target instanceof HTMLInputElement) {
					return;
				}

				if (isEscKey(event) && !event.defaultPrevented) {
					callbackRef.current();
					event.preventDefault();
					uiEvent(`esc-${eventName}`, event.currentTarget);
				}
			};

			window.addEventListener('keydown', cb);

			return () => window.removeEventListener('keydown', cb);
		}

		return () => {
			//
		};
	}, [callback, isTopMost]);

	return isTopMost;
};
