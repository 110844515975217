import { useFeatureFlag } from '@smile/experimentation';
import type { ReactNode, FC } from 'react';

export const MobileWrapper: FC<{
	children: ReactNode;
	offsetTop?: number | undefined;
}> = ({ children, offsetTop }) => {
	const nativePosition = useFeatureFlag('use-native-position');

	return (
		<div
			className="widget-top-wrapper"
			style={{
				margin: 'auto',
				...(nativePosition
					? { height: '100%', alignContent: 'end' }
					: {
							marginTop: offsetTop ?? 70,
						}),
			}}
		>
			{children}
		</div>
	);
};

// used by white-ibis
export const DesktopWrapper652: FC<{
	children: ReactNode;
	offsetTop?: number | undefined;
}> = ({ children, offsetTop }) => (
	<div
		className="widget-top-wrapper"
		style={{
			margin: 'auto',
			marginTop: offsetTop ?? 100,
		}}
	>
		{children}
	</div>
);

export const DesktopWrapper: FC<{
	children: ReactNode;
	offsetTop?: number | undefined;
}> = ({ children, offsetTop }) => (
	<div
		className="widget-top-wrapper"
		style={{
			margin: 'auto',
			marginTop: offsetTop ?? 100,
		}}
	>
		{children}
	</div>
);
