import { css } from '@linaria/core';
import { color } from '@smile/tokens/color';
import { palette } from '@smile/tokens/palette';

export const lightTheme = css`
	--text-primary: var(--custom-primary-color, ${palette.baseBlue[500]});
	--text-muted: ${palette.gray[500]};

	[data-theme='zip'] & {
		--text-primary: #1a0826;
		--custom-primary-action-bg: #411361;
	}

	[data-theme='zip-us'] & {
		--text-primary: #1a0826;
		--custom-primary-action-bg: #6542be;
	}

	[data-theme='humanitix'] & {
		--text-primary: #030303;
		--custom-primary-action-bg: #ffb18f;
	}

	[data-theme='ebay'] & {
		--text-primary: #191919;
		--custom-primary-action-bg: #3665f3;
	}

	[data-theme='try-booking'] & {
		--text-primary: #002140;
		--custom-primary-action-bg: #357398;
	}

	[data-theme='sezzle'] & {
		--custom-primary-action-bg: #382757;
	}
`;

export const darkTheme = css`
	--text-primary: var(--custom-secondary-color, ${palette.base.white});
	--text-muted: ${palette.gray[500]};

	[data-theme='zip'] & {
		--text-primary: #fffffa;
		--custom-secondary-action-bg: #6542be;
	}

	[data-theme='zip-us'] & {
		--text-primary: #1a0826;
		--custom-secondary-action-bg: #6542be;
	}

	[data-theme='humanitix'] & {
		--text-primary: #030303;
		--custom-secondary-action-bg: #030303;
	}

	[data-theme='ebay'] & {
		--text-primary: #191919;
		--custom-secondary-action-bg: #3665f3;
	}

	[data-theme='try-booking'] & {
		--text-primary: #ffffff;
		--custom-secondary-action-bg: #ffffff;
	}

	[data-theme='sezzle'] & {
		--custom-secondary-action-bg: #ffffff;
	}
`;

export const component = css`
	transition-property: background-color;
	transition-duration: 300ms;
`;

export const primary = css`
	background-color: var(--custom-primary-bg, ${color.surface.primary});

	[data-theme='zip'] & {
		background-color: #ffffff;
	}

	[data-theme='zip-us'] & {
		background-color: #ffffff;
	}

	[data-theme='humanitix'] & {
		background-color: #ffffff;
	}

	[data-theme='ebay'] & {
		background-color: #ffffff;
	}

	[data-theme='try-booking'] & {
		background-color: #ffffff;
	}
`;

export const secondary = css`
	background-color: ${color.surface.secondary};

	[data-theme='zip'] & {
		background-color: #aa8fff;
	}

	[data-theme='try-booking'] & {
	}
`;

export const tertiary = css`
	background-color: var(--custom-secondary-bg, ${color.surface.tertiary});

	[data-theme='zip'] & {
		background-color: #1a0826;
	}

	[data-theme='zip-us'] & {
		background-color: #dbccff;
	}

	[data-theme='humanitix'] & {
		background-color: #ffe9df;
	}

	[data-theme='ebay'] & {
		background-color: #c5e5fb;
	}

	[data-theme='try-booking'] & {
		background-color: #03517e;
	}

	[data-theme='sezzle'] & {
		background-color: #382757;
	}
`;
