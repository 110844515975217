import { createWebTunnelPrimary, createRNTunnelPrimary } from '@rock/tunnel';
import type {
	COMMANDS_TO_WIDGET,
	COMMANDS_TO_HOST,
} from '@thanksjs/iframe-integration';
import {
	$boolean,
	$object,
	$record,
	$opt,
	$string,
	type Infer,
	$union,
	lizodParse,
} from '@theway/lizod';

const initPayload = $object(
	{
		statusText: $string,
		// hash: $opt($array($string)),
		referral: $string,
		taint: $opt($string),
		allowLocalStorage: $opt($boolean),
		flags: $opt(
			$object(
				{
					switches: $opt(
						$record($string, $union([$boolean, $string])),
					),
				},
				false,
			),
		),
	},
	false,
);

declare global {
	interface Window {
		__INTEGRATION_MODE__?: string;
		messageToThanks?: (message: string) => void;
	}
}

const constructTunnel = (mode: 'web' | 'rn') => {
	switch (mode) {
		case 'web': {
			return createWebTunnelPrimary<COMMANDS_TO_WIDGET, COMMANDS_TO_HOST>(
				'rocket',
			);
		}

		case 'rn': {
			const tunnel = createRNTunnelPrimary<
				COMMANDS_TO_WIDGET,
				COMMANDS_TO_HOST
			>('rocket');
			window.messageToThanks = tunnel.pushMessage;

			return tunnel;
		}

		default:
			throw new Error('unknown mode');
	}
};

export const tunnel = constructTunnel(
	window.__INTEGRATION_MODE__ === 'react-native' ? 'rn' : 'web',
);

export type TUNNEL_TYPE = typeof tunnel;

export type InitPayload = Infer<typeof initPayload>;

export const initTunnel = (
	connectTo: Window,
	init: (
		tunnel: TUNNEL_TYPE,
		payload: InitPayload,
		channel: typeof tunnel,
		message: unknown,
	) => undefined | Promise<string | void>,
) => {
	const forceClose = (reason: string) => {
		if (!reason.startsWith('ok:')) {
			console.error('ThanksAd: unfortunately an error occurred');
			console.error(reason);
		}

		tunnel.send('close');

		setTimeout(() => {
			tunnel.send('terminate');
		}, 1000);
	};

	tunnel.on('init', async (message) => {
		try {
			const errorCode = await init(
				tunnel,
				lizodParse(message, initPayload),
				tunnel,
				message,
			);

			if (errorCode) {
				forceClose(errorCode);
			}
		} catch (e) {
			forceClose(e instanceof Error ? e.message : 'reason unknown');

			// let Sentry consume the error
			throw e;
		}
	});

	tunnel.connect(connectTo);
};
