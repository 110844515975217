import { css } from '@linaria/core';

export const footer = css`
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;

	padding-inline: 16px;
	height: 25px;
`;
