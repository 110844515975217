import { captureException } from '@theway/metrics';
import type { CSSProperties, RefObject } from 'react';
import { useEffect, useMemo, useState } from 'react';

export const hslToRgb = (
	hue: number,
	saturation: number,
	lightness: number,
) => {
	let r, g, b;

	if (saturation === 0) {
		r = g = b = lightness; // achromatic
	} else {
		const hue2rgb = (p: number, q: number, t: number) => {
			if (t < 0) t += 1;
			if (t > 1) t -= 1;
			if (t < 1 / 6) return p + (q - p) * 6 * t;
			if (t < 1 / 2) return q;
			if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;

			return p;
		};

		const q =
			lightness < 0.5
				? lightness * (1 + saturation)
				: lightness + saturation - lightness * saturation;
		const p = 2 * lightness - q;
		r = hue2rgb(p, q, hue + 1 / 3);
		g = hue2rgb(p, q, hue);
		b = hue2rgb(p, q, hue - 1 / 3);
	}

	return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
};

const parseToRgb = (color: string) => {
	const hexRegex = /^#[a-fA-F0-9]{6}$/;
	const reducedHexRegex = /^#[a-fA-F0-9]{3}$/;

	if (typeof color !== 'string') {
		throw new Error('Invalid color format');
	}

	if (color.match(hexRegex)) {
		return {
			red: parseInt(color.slice(1, 3), 16),
			green: parseInt(color.slice(3, 5), 16),
			blue: parseInt(color.slice(5, 7), 16),
		};
	}

	if (color.match(reducedHexRegex)) {
		return {
			red: parseInt(color[1] + color[1], 16),
			green: parseInt(color[2] + color[2], 16),
			blue: parseInt(color[3] + color[3], 16),
		};
	}

	throw new Error('Invalid color format');
};

const rgbToHsl = ({
	red,
	green,
	blue,
}: {
	red: number;
	green: number;
	blue: number;
}) => {
	red /= 255;
	green /= 255;
	blue /= 255;

	const max = Math.max(red, green, blue);
	const min = Math.min(red, green, blue);
	let hue = 0;
	let saturation = 0;

	const lightness = (max + min) / 2;

	if (max === min) {
		hue = saturation = 0; // achromatic
	} else {
		const d = max - min;
		saturation = lightness > 0.5 ? d / (2 - max - min) : d / (max + min);

		switch (max) {
			case red:
				hue = (green - blue) / d + (green < blue ? 6 : 0);
				break;
			case green:
				hue = (blue - red) / d + 2;
				break;
			case blue:
				hue = (red - green) / d + 4;
				break;
			default:
				break;
		}

		hue /= 6;
	}

	return { hue, saturation, lightness };
};

const toColorString = (hsl: {
	hue: number;
	saturation: number;
	lightness: number;
}) => {
	return `hsl(${hsl.hue * 360}, ${hsl.saturation * 100}%, ${hsl.lightness * 100}%)`;
};

const parseToHsl = (color: string) => {
	return rgbToHsl(parseToRgb(color));
};

const guard = (min: number, max: number, value: number) => {
	return Math.min(max, Math.max(min, value));
};

const lighten = (amount: number, color: string) => {
	const hslColor = parseToHsl(color);

	return toColorString({
		...hslColor,
		lightness: guard(0, 1, hslColor.lightness + amount),
	});
};

export const getLightenColor = (color: string) => {
	if (typeof color !== 'string') {
		return '';
	}

	const hsl = parseToHsl(color);
	const lightnessIncrease = hsl.lightness < 0.5 ? 0.3 : 0.1;

	return lighten(lightnessIncrease, color);
};

export const useOutlineColor = (
	elementRef: RefObject<HTMLElement>,
	styles: CSSProperties,
) => {
	const [primaryOutline, setPrimaryOutline] = useState('');

	useEffect(() => {
		if (elementRef.current) {
			const computedStyle = getComputedStyle(elementRef.current);
			const primaryColor = computedStyle
				.getPropertyValue('--primary-action-background')
				.trim();

			const brightColor = getLightenColor(primaryColor);

			// TODO just here for troubleshooting, remove once trouble is shot.
			if (brightColor === '') {
				captureException(
					new Error(
						`lighten color failed. color to lighten was: ${primaryColor}. HTMLElement was ${elementRef.current.outerHTML}. Computed styles were ${JSON.stringify(computedStyle)}`,
					),
				);
			}

			setPrimaryOutline(brightColor);
		}
	}, []);

	return useMemo(
		() => ({
			...styles,
			'--outline-color': primaryOutline,
		}),
		[styles, primaryOutline],
	);
};
