import { uid } from 'uid';

type FixedLengthString<
	L extends number,
	S extends string = '',
> = S['length'] extends L ? S : S & { _brand: string };

type WIDGET_UID = FixedLengthString<32>; //48

const genUID = <Length extends number>(
	prefix: string,
	length: Length,
): FixedLengthString<Length> => {
	return `${prefix}${uid(length - prefix.length)}` as any;
};

/**
 * session id generation is aligned with the userflux session id generation
 */
export const getSessionId = () =>
	'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
		const r = (Math.random() * 16) | 0,
			v = c == 'x' ? r : (r & 0x3) | 0x8;

		return v.toString(16);
	});

export const getImpressionId = (
	type: 'widget' | 'embed',
	region: string,
): WIDGET_UID => {
	const ari = 'imp';
	const typePrefix = type === 'widget' ? 'w' : 'e';
	const regionPrefix = region;

	return genUID(`${ari}${typePrefix}${regionPrefix}`, 32);
};

export const extractImpressionRegion = (impressionId: string): 'AU' | 'US' => {
	// impwAU1234 -> AU
	return impressionId.slice(4, 6) as any;
};

export const getPiiId = (
	type: 'provided' | 'collected',
	region: string,
): WIDGET_UID => {
	const ari = 'pii';
	const typePrefix = type === 'provided' ? 'p' : 'c';
	const regionPrefix = region;

	return genUID(`${ari}${typePrefix}${regionPrefix}`, 32);
};
