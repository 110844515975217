import { Logo } from '@smile/ds/logo';
import type { SlideType } from '@smile/widget-common/types';
import { analyticsAttribution } from '@theway/analytics';
import { clsx } from 'clsx';
import type { FC, KeyboardEventHandler, PropsWithChildren } from 'react';

import fiveIcon from './assets/five.svg';
import fourIcon from './assets/four.svg';
import oneIcon from './assets/one.svg';
import thanksInactiveIcon from './assets/thanks-inactive.svg';
import thanksIcon from './assets/thanks.svg';
import threeIcon from './assets/three.svg';
import twoIcon from './assets/two.svg';
import * as styles from './styles';

export type PaginationControlProps = {
	activeSlide: number;
	maximumSlide: number;
	slides: SlideType[];
	withBrandItem: boolean;
	onSetSlide(slide: number): void;
};

type PaginationProps = PaginationControlProps & {
	gap?: number;
};

type PaginationButtonProps = {
	label: string;
	fromSlide: number;
	toSlide: number;
	active: boolean;
	setSlide(slide: number): void;
};

const PaginationButton: FC<PaginationButtonProps & PropsWithChildren> = ({
	label,
	children,
	fromSlide,
	toSlide,
	setSlide,
	active,
}) => {
	const isYetDisabled = toSlide > fromSlide;
	const isDisabled = false; // allow all buttons to be clicked

	return (
		<button
			className={clsx(styles.action, active && styles.active)}
			disabled={isDisabled}
			aria-label={label}
			role="tab"
			aria-selected={active}
			// aria-controls={`slide-${toSlide}`} // not working for "dynamic content"
			title={!isDisabled ? label : undefined}
			tabIndex={active ? 0 : -1}
			onClick={() => setSlide(toSlide)}
			{...analyticsAttribution(
				isYetDisabled ? 'pagination-disabled' : 'pagination',
			)}
		>
			{children}
		</button>
	);
};

export const ICONS: Record<string, string> = {
	0: oneIcon,
	1: twoIcon,
	2: threeIcon,
	3: fourIcon,
	4: fiveIcon,
};

const BRAND_ITEM = { img: thanksIcon, bg: 'var(--brand-color)' };

export const Pagination: FC<PaginationProps> = ({
	activeSlide,
	maximumSlide,
	slides,
	gap = 16,
	onSetSlide,
	withBrandItem,
}) => {
	const mod_slides: SlideType[] = [
		...slides,
		...(withBrandItem ? [BRAND_ITEM] : []),
	];

	const numbersOfSlides = mod_slides.length;

	const inactiveImg = [
		...slides.map((_, index) => ICONS[index]),
		...(withBrandItem ? [thanksInactiveIcon] : []),
	];

	const paginationButtonProps = {
		fromSlide: maximumSlide,
		setSlide: onSetSlide,
	} as const;

	const onKeyDown: KeyboardEventHandler = (event) => {
		switch (event.key) {
			case 'ArrowLeft':
				// Go to previous tab if not at the first tab
				if (activeSlide > 1) {
					onSetSlide(activeSlide - 1);
				}

				break;
			case 'ArrowRight':
				// Go to next tab if not at the last tab
				if (activeSlide < numbersOfSlides) {
					onSetSlide(activeSlide + 1);
				}

				break;
			default:
				break;
		}
	};

	return (
		<ul
			className={styles.list}
			style={{
				...styles.countVar(numbersOfSlides),
				...styles.gapVar(gap),
			}}
			onKeyDown={onKeyDown}
		>
			{mod_slides.map((slide, index) => {
				const currentSlide = index + 1;

				return (
					<li
						key={index}
						style={{
							...styles.bgVar(slide.bg),
							...styles.strokeVar(slide.stroke || slide.bg),
						}}
					>
						<PaginationButton
							active={activeSlide === currentSlide}
							label={`See offer ${index + 1}`}
							toSlide={currentSlide}
							{...paginationButtonProps}
						>
							{activeSlide === currentSlide &&
							currentSlide === numbersOfSlides &&
							withBrandItem ? (
								<Logo
									className={styles.logo}
									theme={'light'}
									width={56}
								/>
							) : (
								<img
									src={
										activeSlide === currentSlide
											? slide.img
											: currentSlide <= maximumSlide
												? slide.img
												: inactiveImg[index]
									}
									alt=""
									role="presentation"
									style={{
										borderRadius: '50%',
										opacity:
											activeSlide === currentSlide
												? 1
												: currentSlide <= maximumSlide
													? 0.4
													: 1,
									}}
								/>
							)}
						</PaginationButton>
					</li>
				);
			})}
		</ul>
	);
};
